<template>
  <div>
    <!--电影列表，与推荐列表-->
    <el-row v-if="totalSize !== 0" id="movie-list">
      <!--电影列表-->
      <el-col :md="18">
        <el-col v-for="(item, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
          <audio-card :audio="item"/>
        </el-col>
        <!--
          分页按钮：
          page-size：每页显示条数
          total：总条数
          hide-on-single-page： 页数为一时隐藏
        -->
        <el-col :span="24" class="pagination">
          <el-pagination
            background
            :small="screenWidth <= 768"
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="currentPage"
            :total="totalSize"
          />
        </el-col>
      </el-col>
    </el-row>
    <el-row v-else class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-result.png">
        <div>没有音频数据</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AudioCard from '@/components/card/AudioCard'

import { getAudios} from "@/api/audio";

export default {
  name: 'Audio',
  components: {AudioCard},
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      showEmpty: true
    }
  },
  created() {
    document.title = '音频主页'

    this.getAudiosWrapper(this.currentPage)
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    getAudiosWrapper(page) {
      getAudios(page).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        }
      })
    }
  }
}
</script>

<style scoped>
#movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.pagination {
  text-align: center;
  padding: 10px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  #movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}
</style>
